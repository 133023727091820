import { Link, useLocation } from 'react-router-dom';
import { HStack, Button } from '@chakra-ui/react';
import {
  IconCalculator,
  IconHome,
  IconInfoCircle,
  IconMap,
} from '@tabler/icons-react';

const MenuBar = () => {
  const location = useLocation();

  const isCurrentPath = (path: string) => location.pathname === path;
  const menuItems = [
    { name: 'Home', path: '/', icon: <IconHome /> },
    { name: 'Calculator', path: '/calculator', icon: <IconCalculator /> },
    { name: 'Map', path: '/map', icon: <IconMap /> },
    { name: 'About', path: '/about', icon: <IconInfoCircle /> },
  ];

  return (
    <HStack
      spacing={4}
      margin={'auto'}
      justifyContent={'space-around'}
      maxW={{ base: 'full', md: '90%', lg: '70%' }}
    >
      {menuItems.map((item) => (
        <Button
          key={item.name}
          variant={isCurrentPath(item.path) ? 'solid' : 'ghost'}
          colorScheme="green"
          leftIcon={item.icon}
          as={Link}
          to={item.path}
        >
          {item.name}
        </Button>
      ))}
    </HStack>
  );
};

export default MenuBar;
