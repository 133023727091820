import { Table, TableCaption, Tbody, Td, Text, Tr } from '@chakra-ui/react';

import { formatNumber } from '../../Utils/util';
import { GeoData } from '../../types';

interface GeoDetailsProps {
  details: GeoData;
}

const GeoDetailsTable = ({ details }: GeoDetailsProps) => {
  if (!details.numberOfProperties) {
    return (
      <Text fontWeight={'medium'} color={'gray'} textAlign={'center'}>
        No data available.
      </Text>
    );
  }

  return (
    <>
      <Text fontWeight={'medium'} paddingTop={'1rem'}>
        Details
      </Text>
      <Table size={{ base: 'sm', md: 'md' }}>
        {Number.isFinite(details.numberOfProperties) && (
          <TableCaption>
            Sampled number of properties:{' '}
            {formatNumber(details.numberOfProperties ?? 0)}
          </TableCaption>
        )}
        <Tbody>
          {Number.isFinite(details.averagePrice) && (
            <Tr>
              <Td className="td-start">Average price</Td>
              <Td className="td-end" textAlign={'end'}>
                {formatNumber(details.averagePrice ?? 0)} €
              </Td>
            </Tr>
          )}
          {Number.isFinite(details.averagePricePerM2) && (
            <Tr>
              <Td className="td-start">Average price</Td>
              <Td className="td-end" textAlign={'end'}>
                {formatNumber(details.averagePricePerM2 ?? 0)} €/m²
              </Td>
            </Tr>
          )}
          {Number.isFinite(details.averageLivingArea) && (
            <Tr>
              <Td className="td-start">Average living area</Td>
              <Td className="td-end" textAlign={'end'}>
                {formatNumber(details.averageLivingArea ?? 0)} m²
              </Td>
            </Tr>
          )}
          {Number.isFinite(details.averageTotalArea) && (
            <Tr>
              <Td className="td-start">Average total area</Td>
              <Td className="td-end" textAlign={'end'}>
                {formatNumber(details.averageTotalArea ?? 0)} m²
              </Td>
            </Tr>
          )}
          {Number.isFinite(details.averageNumberOfRooms) && (
            <Tr>
              <Td className="td-start">Average number of rooms</Td>
              <Td className="td-end" textAlign={'end'}>
                {formatNumber(details.averageNumberOfRooms ?? 0)}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </>
  );
};

export default GeoDetailsTable;
