class ApiService {
  /**
   * Makes a GET request to the specified endpoint with optional query parameters.
   * @param endpoint The endpoint to request. Should start with a slash ('/').
   * @param queryParams Optional query parameters to include in the request.
   * @returns The response data if the request is successful, or an object with an 'error' property if the request fails.
   */
  async get(endpoint: string, queryParams?: Record<string, string>) {
    const url = new URL(`${process.env.REACT_APP_API_URL}${endpoint}`);

    if (queryParams) {
      Object.entries(queryParams).forEach(([key, value]) => {
        url.searchParams.append(key, value);
      });
    }

    try {
      const response = await fetch(url.toString());

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Fetch error: ${error}`); // TODO: debug only, remove later
      if (error instanceof Error) {
        throw error;
      } else {
        throw new Error('An unknown error occurred');
      }
    }
  }

  /**
   * Makes a POST request to the specified endpoint with the given payload.
   * @param endpoint The endpoint to request. Should start with a slash ('/').
   * @param payload The payload to include in the request.
   * @returns The response data if the request is successful, or an object with an 'error' property if the request fails.
   */
  async post(endpoint: string, payload: Record<string, unknown>) {
    const url = new URL(`${process.env.REACT_APP_API_URL}${endpoint}`);

    try {
      const response = await fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Fetch error: ${error}`); // TODO: debug only, remove later
      if (error instanceof Error) {
        throw error;
      } else {
        throw new Error('An unknown error occurred');
      }
    }
  }
}

export default new ApiService();
