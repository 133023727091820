import {
  CardBody,
  Card as ChakraCard,
  Heading,
  Image,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';

interface CardProperties {
  title?: string;
  subheading?: string;
  description?: string;
  image?: string;
  isLoading: boolean;
  children?: React.ReactNode;
}

const Card = ({
  title,
  description,
  subheading,
  children,
  image,
  isLoading,
}: CardProperties) => {
  if (isLoading) {
    return (
      <ChakraCard overflow="hidden" variant="outline">
        <Skeleton h={'200px'} />
        <CardBody>
          <Stack gap={6}>
            <Stack gap={2}>
              <Skeleton h={8} />
              <Skeleton h={4} />
            </Stack>
            <Stack gap={2}>
              {Array.from({ length: 7 }, (e, idx: number) => (
                <Skeleton key={idx} h={4} />
              ))}
            </Stack>
          </Stack>
        </CardBody>
      </ChakraCard>
    );
  }

  return (
    <ChakraCard overflow="hidden" variant="outline" overflowY={'auto'}>
      <Image
        objectFit="cover"
        maxH={{ base: '200px', md: '400px', lg: '250px' }}
        src={`data:image/png;base64,${image}`}
        alt="card_image"
      />

      <CardBody>
        <Heading size="md">{title}</Heading>
        {subheading && (
          <Text color={subheading} fontSize="sm" fontWeight="500">
            {subheading}
          </Text>
        )}
        <Text py="2">{description}</Text>
        {children}
      </CardBody>
    </ChakraCard>
  );
};

export default Card;
