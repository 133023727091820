import { ReactNode } from 'react';
import { Flex, Heading, Skeleton, Text } from '@chakra-ui/react';

import { GeoDetails } from '../../types';
import { ListingType, PropertyType, pulseAnimation } from '../../constants';
import { formatNumber } from '../../Utils/util';

import RandomDigits from '../RandomDigits';

interface ResultProperties {
  isFetching: boolean;
  details?: GeoDetails;
  prediction?: number;
}

const Result = ({ details, isFetching, prediction }: ResultProperties) => {
  const content = isFetching ? (
    <>
      <Skeleton h={4} opacity={0.4} />
      <Heading
        size={{ base: 'lg', xl: 'xl' }}
        css={isFetching ? { animation: pulseAnimation } : {}}
      >
        <RandomDigits digitLength={6} postFix="€" />
      </Heading>
      <Skeleton h={4} opacity={0.3} />
    </>
  ) : prediction ? (
    <>
      <Text fontSize={{ base: 'sm', xl: 'base' }}>
        Estimated price for the selected property details
      </Text>
      <Heading size={{ base: 'lg', xl: 'xl' }}>
        {prediction ? `${formatNumber(prediction)} €` : 'N/A'}
      </Heading>
    </>
  ) : details ? (
    <>
      <Text fontSize={{ base: 'sm', xl: 'base' }}>
        Average price in {details.name}
      </Text>
      <Heading size={{ base: 'lg', xl: 'xl' }}>
        {details?.averagePrice
          ? `${formatNumber(details.averagePrice)} €`
          : 'N/A'}
      </Heading>
      {details?.numberOfProperties ? (
        <Text fontSize={'xs'}>
          Based on {`${formatNumber(details.numberOfProperties)}`}{' '}
          {details.propertyType === PropertyType.house
            ? 'houses'
            : 'apartments'}{' '}
          for {details.listingType === ListingType.sale ? 'sale' : 'rent'}
        </Text>
      ) : (
        <Text fontSize={'xs'}>
          No data available yet. Check out other listing and property types or
          districts.
        </Text>
      )}
    </>
  ) : (
    <></>
  );

  return <ResultWrapper>{content}</ResultWrapper>;
};

const ResultWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Flex
      direction={'column'}
      backgroundColor={'green.500'}
      textColor={'white'}
      textAlign={'center'}
      placeContent={'center'}
      px={4}
      py={{ base: 4, md: 16, lg: 32 }}
      gap={4}
      rounded={'md'}
      h={'min-content'}
      position={{ base: 'relative', md: 'sticky' }}
      right={0}
      top={'50%'}
      transform={'translateY(-50%)'}
    >
      {children}
    </Flex>
  );
};

export default Result;
