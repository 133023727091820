import { Dispatch, SetStateAction } from 'react';
import {
  Box,
  Text,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Grid,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
  AlertIcon,
  Alert,
  FormControl,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';
import { IconCalculator } from '@tabler/icons-react';

import { Filters } from '../../Pages/Calculator';

interface DetailedOptionsProperties {
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
  disabled: boolean;
  isPredicting: boolean;
  isError: boolean;
  onSubmit: () => void;
}

const DetailedOptions = ({
  filters,
  setFilters,
  disabled,
  isPredicting,
  isError,
  onSubmit,
}: DetailedOptionsProperties) => {
  const format = (value: string) => `${value} m²`;
  const parse = (value: string) => value.replace(/^\m²/, '');

  return (
    <>
      <Text w={'full'} fontWeight={'medium'}>
        Number of rooms
      </Text>
      <Box pt={4} w={'full'}>
        <Slider
          defaultValue={filters.numberOfRooms}
          onChange={(val) =>
            setFilters((prev) => ({ ...prev, numberOfRooms: val }))
          }
          min={1}
          max={10}
        >
          {Array.from({ length: 10 }).map((_, i) => (
            <SliderMark key={i} value={i + 1} />
          ))}
          <SliderMark
            value={filters.numberOfRooms}
            textAlign="center"
            rounded={'full'}
            bg={'green.500'}
            color={'white'}
            mt={'-10'}
            ml={'-3'}
            w={'6'}
          >
            {filters.numberOfRooms}
          </SliderMark>
          <SliderTrack bg={'green.200'}>
            <SliderFilledTrack bg={'green.500'} />
          </SliderTrack>
          <SliderThumb _focusVisible={{ boxShadow: '0 0 0 3px #48BB78' }} />
        </Slider>
      </Box>
      <Grid
        gridTemplateColumns={'repeat(2, 1fr)'}
        gap={{ base: 4, md: 6 }}
        w={'full'}
      >
        <FormControl>
          <FormLabel>Living area</FormLabel>
          <NumberInput
            onChange={(valueString) =>
              setFilters((prev) => ({
                ...prev,
                livingArea: parseInt(parse(valueString)),
              }))
            }
            value={
              filters.livingArea
                ? format(filters.livingArea.toString())
                : undefined
            }
            step={5}
            min={10}
            max={1000}
            keepWithinRange
            isValidCharacter={(char) => /[0-9]/.test(char)}
          >
            <NumberInputField placeholder="4242 m²" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl
          isInvalid={
            !!filters.totalArea && filters.totalArea < filters.livingArea
          }
        >
          <FormLabel>Total area</FormLabel>
          <NumberInput
            onChange={(valueString) =>
              setFilters((prev) => ({
                ...prev,
                totalArea: parseInt(parse(valueString)),
              }))
            }
            value={
              filters.totalArea
                ? format(filters.totalArea.toString())
                : undefined
            }
            step={10}
            min={10}
            max={6000}
            keepWithinRange
            isValidCharacter={(char) => /[0-9]/.test(char)}
          >
            <NumberInputField placeholder="4242 m²" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          {!!filters.totalArea && filters.totalArea < filters.livingArea && (
            <FormHelperText color={'red.500'}>
              Total area must be greater than or equal to living area
            </FormHelperText>
          )}
          {!filters.totalArea && (
            <FormHelperText>If unset, equals living area</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Button
        colorScheme={'green'}
        rounded={'xl'}
        size={'lg'}
        disabled={disabled}
        isDisabled={disabled}
        onClick={onSubmit}
        rightIcon={<IconCalculator />}
        isLoading={isPredicting}
        loadingText="Calculating..."
      >
        Calculate
      </Button>
      {isError && (
        <Alert status="error" fontSize={'small'} rounded={'lg'}>
          <AlertIcon />
          There was an error processing your request, please try again.
        </Alert>
      )}
    </>
  );
};

export default DetailedOptions;
