export const getDistrictColor = (
  price: number,
  minPrice: number,
  maxPrice: number,
) => {
  const percentage = (price - minPrice) / (maxPrice - minPrice);
  const red = Math.round(255 * percentage);
  const green = Math.round(255 * (1 - percentage));
  return `rgb(${red}, ${green}, 0)`;
};

export const robustScaler = (
  data: number[],
  lowerPercentile: number,
  upperPercentile: number,
) => {
  // Sort the data
  const sortedData = data.slice().sort((a, b) => a - b);

  // Find the lower and upper percentile values
  const lowerIndex = Math.floor(sortedData.length * lowerPercentile);
  const upperIndex = Math.floor(sortedData.length * upperPercentile);
  const lowerValue = sortedData[lowerIndex];
  const upperValue = sortedData[upperIndex];

  // Scale the data based on the percentile values
  const scaledData = data.map((value) => {
    if (value < lowerValue) {
      return lowerValue;
    } else if (value > upperValue) {
      return upperValue;
    } else {
      return value;
    }
  });

  return scaledData;
};

export const formatNumber = (number: number) =>
  new Intl.NumberFormat('de-AT', { maximumFractionDigits: 0 }).format(number);
