import { useEffect, useState } from 'react';
import { Image, Box, Grid, GridItem, Spacer, Flex } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import { useIsFetching } from '@tanstack/react-query';

import Sidebar from './Sidebar';
import MenuBar from './Menubar';
import { pulseAnimation } from '../constants';

const Header = () => {
  const [isSmallerThanMd] = useMediaQuery('(max-width: 767px)');
  const isFetching = useIsFetching();
  const [isFetchingReq, setIsFetchingReq] = useState(false);

  useEffect(() => {
    const timeout = async () => {
      setIsFetchingReq(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsFetchingReq(false);
    };
    if (isFetching) {
      timeout();
    }
  }, [isFetching]);

  return (
    <Box
      as="header"
      position={'sticky'}
      top={0}
      mb={4}
      bg={'light.100'}
      zIndex={2}
    >
      <Grid
        templateColumns={'auto 1fr auto'}
        templateRows={'1fr'}
        alignItems="center"
        gap={6}
        p={4}
      >
        {isSmallerThanMd ? <Sidebar /> : <Box boxSize={'45px'} />}
        {!isSmallerThanMd ? (
          <GridItem colStart={2}>
            <MenuBar />
          </GridItem>
        ) : (
          <Spacer />
        )}
        <Image
          boxSize="45px"
          objectFit="cover"
          src={'/logo512.png'}
          alt="Logo"
        />
      </Grid>
      <Flex
        h={'2px'}
        w={'full'}
        bg={isFetchingReq ? 'green' : 'lightgray'}
        css={isFetchingReq ? { animation: pulseAnimation } : {}}
      />
    </Box>
  );
};

export default Header;
