import { useEffect, useState } from 'react';

import { formatNumber } from '../Utils/util';

interface RandomDigitsProps {
  digitLength: number;
  postFix?: string;
}

const RandomDigits = ({ digitLength, postFix }: RandomDigitsProps) => {
  const [digits, setDigits] = useState(1000);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomDigits = Math.floor(
        Math.random() * Math.pow(10, digitLength),
      );
      setDigits(randomDigits);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [digitLength]);

  return (
    <span>
      {formatNumber(digits)} {postFix}
    </span>
  );
};

export default RandomDigits;
