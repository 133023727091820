import { keyframes } from '@emotion/react';
import { ProjectionConfig } from 'react-simple-maps';
import { RecursivePartial, IOptions } from '@tsparticles/engine';

export const mapConfig: ProjectionConfig = {
  scale: 10000,
  center: [13.25, 47.75],
};

export enum ListingType {
  sale = 'sale',
  rent = 'rent',
}

export enum PropertyType {
  house = 'house',
  apartment = 'apartment',
}

export const pulseAnimation = `${`2s cubic-bezier(0.4, 0, 0.6, 1) infinite ${keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .4;
  }
  `}`}`;

export const tsParticlesConfig: RecursivePartial<IOptions> = {
  fullScreen: { enable: false },
  fpsLimit: 90,
  interactivity: {
    events: {
      onClick: {
        enable: true,
        mode: 'push',
      },
      onHover: {
        enable: true,
        mode: 'repulse',
      },
    },
    modes: {
      push: {
        quantity: 1,
      },
      repulse: {
        distance: 100,
        duration: 0.2,
      },
    },
  },
  particles: {
    color: {
      value: '#2F855A',
    },
    links: {
      color: '#2F855A',
      distance: 150,
      enable: true,
      opacity: 0.5,
      width: 1,
    },
    move: {
      direction: 'none',
      enable: true,
      outModes: {
        default: 'bounce',
      },
      random: false,
      speed: 1,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        width: 800,
        height: 800,
      },
      value: 80,
    },
    opacity: {
      value: 0.5,
    },
    shape: {
      type: 'circle',
    },
    size: {
      value: { min: 1, max: 5 },
    },
  },
  detectRetina: true,
};
