import { Box, Heading, Center } from '@chakra-ui/react';

const About = () => {
  return (
    <Center h={'full'}>
      <Box textAlign={'center'}>
        <Heading mb={6}>About Page</Heading>
        <p>This is the about page for the Property Analyzer application.</p>
      </Box>
    </Center>
  );
};

export default About;
