import { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Grid,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IconShoppingCart, IconDoorEnter } from '@tabler/icons-react';

import { GeoDetails } from '../types';
import {
  getCountryDetails,
  getDistrictDetails,
  getDistricts,
  predictPrice,
} from '../Utils/apiCalls';
import { PropertyType, ListingType } from '../constants';

import DetailedOptions from '../Components/Calculator/DetailedOptions';
import PropertyTypeSelector from '../Components/Calculator/PropertyTypeSelector';
import Switch from '../Components/Switch';
import Result from '../Components/Calculator/Result';

export interface Filters {
  country: string;
  district?: string;
  propertyType: PropertyType;
  listingType: ListingType;
  numberOfRooms: number;
  livingArea: number;
  totalArea?: number;
}

const Calculator = () => {
  const [filters, setFilters] = useState<Filters>({
    country: 'Austria', // FIXME replace fixed value
    propertyType: PropertyType.house,
    listingType: ListingType.sale,
    numberOfRooms: 2,
    livingArea: 80,
  });
  const [details, setDetails] = useState<GeoDetails>();
  const [showDetailedOptions, setShowDetailedOptions] = useState(false);
  const {
    data,
    error,
    mutate,
    reset,
    isPending: isPredicting,
  } = useMutation({
    mutationFn: () => predictPrice({ ...filters, district: filters.district! }),
  });
  const { data: districts, isFetching: isFetchingDistricts } = useQuery({
    queryKey: ['districts', filters.country],
    queryFn: async () => {
      reset();
      return getDistricts(filters.country);
    },
    refetchOnWindowFocus: false,
  });
  const { isFetching: isFetchingCountryDetails } = useQuery({
    queryKey: [
      'countryDetails',
      filters.country,
      filters.propertyType,
      filters.listingType,
    ],
    queryFn: async () => {
      reset();
      const result = await getCountryDetails(
        filters.country,
        filters.propertyType,
        filters.listingType,
      );
      setDetails(() => result);
      return result;
    },
    refetchOnWindowFocus: false,
    enabled: !filters.district && !showDetailedOptions && !isPredicting,
  });
  const { isFetching: isFetchingDistrictDetails } = useQuery({
    queryKey: [
      'districtDetails',
      filters.district,
      filters.propertyType,
      filters.listingType,
    ],
    queryFn: async () => {
      reset();
      const result = await getDistrictDetails(
        filters.district!,
        filters.propertyType,
        filters.listingType,
      );
      setDetails(() => result);
      return result;
    },
    refetchOnWindowFocus: false,
    enabled: !!filters.district && !showDetailedOptions && !isPredicting,
  });

  return (
    <Grid
      gridTemplateColumns={{ base: '1fr', md: '1fr 30%' }}
      h={'full'}
      mx={'auto'}
      py={{ base: 4, md: 8 }}
      gap={4}
      w={{ base: 'full', lg: '90%', xl: '70%' }}
      position={'relative'}
    >
      <Flex
        direction={'column'}
        align={'center'}
        border={'2px green solid'}
        rounded={'md'}
        my={'auto'}
        p={{ base: 4, md: 6 }}
        gap={{ base: 6, md: 8 }}
        minH={'50%'}
        h={'min-content'}
      >
        <Box w={'full'}>
          <Heading size="xl" mb={2}>
            Calculate price
          </Heading>
          <Text fontSize={{ base: 'sm', md: 'md' }} color={'gray'}>
            Get average price for a listing and property type in a district or{' '}
            <br />
            detailed price prediction based on the property's features.
          </Text>
        </Box>

        {/* Listing */}
        <Switch
          options={[
            { name: ListingType.sale, icon: <IconShoppingCart /> },
            { name: ListingType.rent, icon: <IconDoorEnter /> },
          ]}
          onChange={(option) =>
            setFilters((prev) => ({
              ...prev,
              listingType: option as ListingType,
            }))
          }
        />
        <Box w={'full'} h={'full'}>
          <PropertyTypeSelector
            selected={filters.propertyType}
            onSelect={(propertyType) =>
              setFilters((prev) => ({
                ...prev,
                propertyType: propertyType as PropertyType,
              }))
            }
          />
        </Box>

        {/* Location */}
        <Stack direction={'row'} mt={4} w={'full'} spacing={4} align={'center'}>
          {[{ name: 'Austria', icon: <>🇦🇹</> }].map((type) => (
            <Button
              key={type.name}
              colorScheme={filters.country === type.name ? 'green' : 'gray'}
              rounded={'xl'}
              size={'lg'}
              onClick={() =>
                setFilters((prev) => ({
                  ...prev,
                  country: type.name,
                  district: undefined,
                }))
              }
              leftIcon={type.icon}
            >
              {type.name}
            </Button>
          ))}
          <Text textColor={'gray.500'}>
            ... other countries to come in the future.
          </Text>
        </Stack>
        <Box w={'full'}>
          <FormControl isInvalid={showDetailedOptions && !filters.district}>
            <Select
              placeholder="Select a district"
              isDisabled={isFetchingDistricts}
              isRequired={showDetailedOptions}
              value={
                filters.district
                  ? { label: filters.district, value: filters.district }
                  : undefined
              }
              options={districts?.map((district) => ({
                label: district,
                value: district,
              }))}
              onChange={(val) =>
                setFilters((prev) => ({
                  ...prev,
                  district: val?.label || undefined,
                }))
              }
              isClearable
              focusBorderColor={'green.700'}
              selectedOptionColorScheme={'green'}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
            />
            {showDetailedOptions && !filters.district && (
              <FormHelperText color={'red.500'}>
                Required for property price prediction
              </FormHelperText>
            )}
          </FormControl>
        </Box>
        <FormControl>
          <Button
            colorScheme={'green'}
            variant={showDetailedOptions ? 'solid' : 'ghost'}
            rounded={'xl'}
            size={'lg'}
            h={'auto'}
            p={2}
            alignSelf={'start'}
            rightIcon={
              showDetailedOptions ? <ChevronUpIcon /> : <ChevronDownIcon />
            }
            onClick={() => setShowDetailedOptions((prev) => !prev)}
          >
            Detailed options
          </Button>
          <FormHelperText pl={showDetailedOptions ? 0 : 2}>
            For property specific price prediction
          </FormHelperText>
        </FormControl>
        {showDetailedOptions && (
          <DetailedOptions
            filters={filters}
            disabled={!filters.district}
            setFilters={setFilters}
            onSubmit={mutate}
            isPredicting={isPredicting}
            isError={!!error}
          />
        )}
      </Flex>
      <Result
        isFetching={
          isFetchingCountryDetails || isFetchingDistrictDetails || isPredicting
        }
        details={details}
        prediction={data}
      />
    </Grid>
  );
};

export default Calculator;
