import { useState } from 'react';
import { Button, Grid } from '@chakra-ui/react';

import { ListingType, PropertyType } from '../constants';

interface SwitchProperties {
  options: { name: PropertyType | ListingType; icon: JSX.Element }[];
  onChange: (option: PropertyType | ListingType) => void;
}

const Switch = ({ options, onChange }: SwitchProperties) => {
  const [selected, setSelected] = useState(options[0].name);

  const handleOptionClick = (option: PropertyType | ListingType) => {
    setSelected(option);
    onChange(option);
  };

  return (
    <Grid
      templateColumns={'repeat(2, 1fr)'}
      w={'full'}
      background={'gray.100'}
      rounded={'xl'}
      boxShadow={'md'}
    >
      {options.map((option) => (
        <Button
          key={option.name}
          onClick={() => handleOptionClick(option.name)}
          size={'sm'}
          w={'full'}
          px={4}
          rounded={'xl'}
          colorScheme={option.name === selected ? 'green' : 'gray'}
          leftIcon={option.icon}
        >
          {option.name}
        </Button>
      ))}
    </Grid>
  );
};

export default Switch;
