import { Link, useLocation } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
  VStack,
  Button,
  Heading,
  Flex,
} from '@chakra-ui/react';
import {
  IconCalculator,
  IconHome,
  IconInfoCircle,
  IconMap,
} from '@tabler/icons-react';

const Sidebar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  const isCurrentPath = (path: string) => location.pathname === path;
  const menuItems = [
    { name: 'Home', path: '/', icon: <IconHome /> },
    { name: 'Calculator', path: '/calculator', icon: <IconCalculator /> },
    { name: 'Map', path: '/map', icon: <IconMap /> },
    { name: 'About', path: '/about', icon: <IconInfoCircle /> },
  ];

  return (
    <>
      <IconButton
        aria-label={'sidebar-icon'}
        icon={<HamburgerIcon />}
        onClick={onOpen}
      />
      <Drawer placement={'left'} isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth={'1px'}>
            <Flex alignItems={'center'} justifyContent={'space-between'}>
              <Heading size={'lg'}>Menu</Heading>
              <DrawerCloseButton />
            </Flex>
          </DrawerHeader>
          <DrawerBody p={2}>
            <VStack align="start">
              {menuItems.map((item) => (
                <Button
                  key={item.name}
                  variant={isCurrentPath(item.path) ? 'solid' : 'ghost'}
                  colorScheme="green"
                  w="full"
                  justifyContent="flex-start"
                  as={Link}
                  to={item.path}
                  onClick={onClose}
                  leftIcon={item.icon}
                >
                  {item.name}
                </Button>
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Sidebar;
