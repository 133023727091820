import { useEffect } from 'react';
import { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import { Box, Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import Header from './Components/Header';

function App() {
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    });
  }, []);

  return (
    <Flex
      position={'relative'}
      direction={'column'}
      h={'100vh'}
      bg={'light.100'}
      overflow={'auto'}
    >
      <Header />
      <Box flex={'1'} w={'95%'} mx={'auto'}>
        <Outlet />
      </Box>
    </Flex>
  );
}

export default App;
