import { FC, useState } from 'react';
import { Box, calc, Center, Grid, Spinner, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { PropertyType, ListingType } from '../constants';
import { GeoDetails } from '../types';
import {
  getCountryDetails,
  getDistrictData,
  getDistrictDetails,
} from '../Utils/apiCalls';

import Card from '../Components/Card';
import MapFilter from '../Components/Map/MapFilter';
import GeoDetailsTable from '../Components/Map/GeoDetails';
import MapChart from '../Components/Map/MapChart';

export interface Filters {
  propertyType: PropertyType;
  listingType: ListingType;
  district?: string;
}

const Map: FC = () => {
  const [filters, setFilters] = useState<Filters>({
    propertyType: PropertyType.house,
    listingType: ListingType.sale,
  });
  const [details, setDetails] = useState<GeoDetails>();
  const { data, isLoading } = useQuery({
    queryKey: ['districtData', filters.propertyType, filters.listingType],
    queryFn: () => getDistrictData(filters.propertyType, filters.listingType),
    refetchOnWindowFocus: false,
  });
  const { isFetching: isFetchingDistrictDetails } = useQuery({
    queryKey: [
      'districtDetails',
      filters.district,
      filters.propertyType,
      filters.listingType,
    ],
    queryFn: async () => {
      const result = await getDistrictDetails(
        filters.district!,
        filters.propertyType,
        filters.listingType,
      );
      setDetails(() => result);
      return result;
    },
    refetchOnWindowFocus: false,
    enabled: !!filters.district,
  });
  const { isFetching: isFetchingCountryDetails } = useQuery({
    queryKey: [
      'countryDetails',
      'Austria', // FIXME replace fixed value
      filters.propertyType,
      filters.listingType,
    ],
    queryFn: async () => {
      const result = await getCountryDetails(
        'Austria', // FIXME replace fixed value
        filters.propertyType,
        filters.listingType,
      );
      setDetails(() => result);
      return result;
    },
    refetchOnWindowFocus: false,
    enabled: !filters.district,
  });

  const handleFilterChange = (
    propertyType: PropertyType,
    listingType: ListingType,
  ) => {
    setFilters((prev) => ({ ...prev, propertyType, listingType }));
  };

  const handleDistrictSelect = (district: string) => {
    setFilters((prev) => ({ ...prev, district }));
  };

  return (
    <Box
      h={calc('100%').subtract('32px').toString()}
      w={'full'}
      position={'relative'}
    >
      <MapFilter onFilterChange={handleFilterChange} />
      <Grid
        h={'full'}
        w={'full'}
        gap={6}
        alignContent={'start'}
        gridTemplateRows={'1fr'}
        gridTemplateColumns={{ sm: '1fr', lg: '25% 1fr' }}
      >
        <Box
          gridRowStart={1}
          gridColumnStart={{ sm: 1, lg: 2 }}
          overflow={{ lg: 'hidden' }}
        >
          {isLoading ? (
            <Center h={'full'} minH={'400px'}>
              <Spinner />
            </Center>
          ) : !data ? (
            <Text>Error</Text>
          ) : (
            <MapChart
              data={data}
              selectedDistrict={filters.district}
              onDistrictSelect={handleDistrictSelect}
            />
          )}
        </Box>
        <Card
          isLoading={isFetchingCountryDetails || isFetchingDistrictDetails}
          title={details?.name}
          subheading={details?.shortInfo}
          description={details?.longInfo}
          image={details?.image}
        >
          {details && <GeoDetailsTable details={details} />}
        </Card>
      </Grid>
    </Box>
  );
};

export default Map;
