import { Box, Heading, Center } from '@chakra-ui/react';

const NotFound = () => {
  return (
    <Center h={'full'}>
      <Box textAlign={'center'}>
        <Heading mb={6}>404 Page Not Found</Heading>
        <p>The page you are looking for does not exist.</p>
      </Box>
    </Center>
  );
};

export default NotFound;
