import {
  Box,
  Heading,
  Text,
  Grid,
  Stack,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';
import { IconCalculator, IconMap } from '@tabler/icons-react';
import Particles from '@tsparticles/react';
import { Link } from 'react-router-dom';

import { tsParticlesConfig } from '../constants';

const Landing = () => {
  const [isSmallerThanMd] = useMediaQuery('(max-width: 48em)');

  return (
    <Grid
      h={'90%'}
      w={{ base: 'full', md: '90%', '2xl': '75%' }}
      templateColumns={{ base: '1fr', md: '1fr 1fr' }}
      templateRows={{ base: '1fr 1fr auto', md: '1fr' }}
      gap={{ base: 6, md: 10 }}
      mx={'auto'}
      justifyItems={'center'}
      alignItems={'center'}
    >
      <Grid
        templateRows={'1fr auto'}
        w={'full'}
        h={'70%'}
        my={'auto'}
        gap={6}
        alignItems={'center'}
      >
        <Stack spacing={6}>
          <Heading
            size={{
              base: 'xl',
              md: '2xl',
              '2xl': '3xl',
            }}
            w={{ base: 'full', sm: '70%', md: '80%' }}
          >
            Analyze real estate markets with ease
          </Heading>
          <Stack spacing={3} w={'max-content'}>
            <Button
              w={'max-content'}
              justifyContent={'flex-start'}
              leftIcon={<IconMap />}
              variant={'outline'}
              colorScheme={'green'}
              as={Link}
              to={'/map'}
            >
              Interactive Map
            </Button>
            <Button
              w={'max-content'}
              justifyContent={'flex-start'}
              leftIcon={<IconCalculator />}
              variant={'outline'}
              colorScheme={'green'}
              as={Link}
              to={'/calculator'}
            >
              Property Price Prediction
            </Button>
          </Stack>
        </Stack>
        {!isSmallerThanMd && <InfoText />}
      </Grid>
      <Box
        position={'relative'}
        h={{ base: 'full', md: '90%' }}
        w={'full'}
        my={'auto'}
      >
        <Particles id="tsparticles" options={tsParticlesConfig} />
      </Box>
      {isSmallerThanMd && <InfoText />}
    </Grid>
  );
};

const InfoText = () => (
  <Text
    alignSelf={'flex-end'}
    textAlign={'justify'}
    textColor={'gray.700'}
    fontSize={'sm'}
    h={'max-content'}
  >
    We aid users in making informed decisions when investing into properties by
    providing key data and insights. Our goal is to simplify the process of
    property investment by providing a one-stop solution for all your analytical
    needs.
  </Text>
);

export default Landing;
