import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  ChakraProvider,
  StyleFunctionProps,
  ThemeConfig,
  extendTheme,
} from '@chakra-ui/react';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import About from './Pages/About';
import Landing from './Pages/Landing';
import Map from './Pages/Map';
import NotFound from './Pages/NotFound';
import Calculator from './Pages/Calculator';

const queryClient = new QueryClient();

const config: ThemeConfig = {
  initialColorMode: 'light',
  // useSystemColorMode: true,
};

const theme = extendTheme({
  config,
  colors: {
    brand: {
      500: '#008000', // green
    },
    light: {
      100: '#f5f5f5', // light gray
      200: '#eeeeee', // lighter gray
      300: '#e0e0e0', // even lighter gray
      400: '#bdbdbd', // lightest gray
    },
    dark: {
      500: '#424242', // dark gray
      600: '#303030', // darker gray
      700: '#212121', // even darker gray
      800: '#121212', // darkest gray
    },
  },
  components: {
    Button: {
      baseStyle: ({ colorMode }: StyleFunctionProps) => ({
        color: colorMode === 'dark' ? 'light.200' : 'dark.700',
        _hover: {
          backgroundColor: colorMode === 'dark' ? 'light.200' : 'dark.700',
        },
      }),
    },
  },
  styles: {
    global: {
      body: ({ colorMode }: StyleFunctionProps) => ({
        color: colorMode === 'dark' ? 'light.200' : 'dark.700',
      }),
    },
  },
});

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: <Landing />,
      },
      {
        path: '/calculator',
        element: <Calculator />,
      },
      {
        path: '/map',
        element: <Map />,
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
