import { ListingType, PropertyType } from '../constants';
import { DistrictSummary, GeoDetails, PredictPayload } from '../types';
import ApiService from './ApiService';

export const getDistricts = async (country: string): Promise<string[]> =>
  ApiService.get(`/locations/districts`, { country });

export const getDistrictData = async (
  propertyType: PropertyType,
  listingType: ListingType,
): Promise<DistrictSummary[]> =>
  ApiService.get('/locations/districts/summary', {
    propertyType,
    listingType,
  });

export const getDistrictDetails = async (
  district: string,
  propertyType: PropertyType,
  listingType: ListingType,
): Promise<GeoDetails> =>
  ApiService.get(`/locations/districts/${district}`, {
    propertyType,
    listingType,
  });

export const getCountryDetails = async (
  country: string,
  propertyType: PropertyType,
  listingType: ListingType,
): Promise<GeoDetails> =>
  ApiService.get(`/locations/countries/${country}`, {
    propertyType,
    listingType,
  });

export const predictPrice = async ({
  district,
  propertyType,
  listingType,
  livingArea,
  totalArea,
  numberOfRooms,
}: PredictPayload): Promise<number> =>
  ApiService.post(`/predictions/predict`, {
    district,
    propertyType,
    listingType,
    livingArea,
    totalArea,
    numberOfRooms,
  });
