import { FC, useState } from 'react';
import { chakra, Stack, Text, Tooltip, Box } from '@chakra-ui/react';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';

import { mapConfig } from '../../constants';
import { DistrictSummary } from '../../types';
import { getDistrictColor, robustScaler } from '../../Utils/util';
import ChartLegend from './ChartLegend';

const ChakraGeography = chakra(Geography);

const geoUrl = '/austria.json';

interface MapProperties {
  data: DistrictSummary[];
  selectedDistrict?: string;
  onDistrictSelect: (district: string) => void;
}

const MapChart: FC<MapProperties> = ({
  data,
  selectedDistrict: selectedDistrictProp,
  onDistrictSelect,
}) => {
  const [selectedDistrict, setSelectedDistrict] = useState<string | undefined>(
    selectedDistrictProp,
  );

  const handleDistrictClick = (districtName: string) => {
    if (selectedDistrict === districtName) {
      setSelectedDistrict(undefined);
      onDistrictSelect('');
      return;
    }
    onDistrictSelect(districtName);
    setSelectedDistrict(districtName);
  };

  const scaledPrices = robustScaler(
    data.map((district) => district.averagePricePerM2 || 0),
    0.05,
    0.95,
  );
  const maxPrice = Math.max(...scaledPrices);
  const minPrice = Math.min(...scaledPrices);

  return (
    <Box position={'relative'} h={'95%'} w={'full'}>
      <ComposableMap
        projectionConfig={mapConfig}
        width={1000}
        height={600}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <ZoomableGroup scale={mapConfig.scale}>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const result = data.find(
                  (district) =>
                    district.district.trim().toLowerCase() ===
                    geo.properties.name.trim().toLowerCase(),
                );
                const price = result?.averagePricePerM2;
                const districtName = geo.properties.name;
                const color = price
                  ? getDistrictColor(price, minPrice, maxPrice)
                  : 'light.100';
                return (
                  <Tooltip
                    key={geo.rsmKey}
                    label={
                      <Stack>
                        <Text>{districtName}</Text>
                        <Text>
                          Average price:{' '}
                          {Number.isFinite(price) ? `${price} Eur/m2` : 'N/A'}
                        </Text>
                      </Stack>
                    }
                  >
                    <ChakraGeography
                      key={geo.rsmKey}
                      geography={geo}
                      outline={'none'}
                      stroke={'black'}
                      strokeWidth={selectedDistrict === districtName ? 1 : 0.3}
                      _hover={{
                        fill: color,
                        opacity: 1,
                        strokeWidth: 1,
                        outline: 'none',
                      }}
                      onClick={() => handleDistrictClick(geo.properties.name)}
                      fill={color}
                      opacity={selectedDistrict === districtName ? 1 : 0.9}
                    />
                  </Tooltip>
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <ChartLegend />
    </Box>
  );
};

export default MapChart;
