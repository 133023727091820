import { useState } from 'react';
import { Stack } from '@chakra-ui/react';
import {
  IconBuildingCommunity,
  IconDoorEnter,
  IconHome,
  IconShoppingCart,
} from '@tabler/icons-react';

import { ListingType, PropertyType } from '../../constants';

import Switch from '../Switch';

interface FilterProperties {
  onFilterChange: (
    propertyType: PropertyType,
    listingType: ListingType,
  ) => void;
}

const MapFilter = ({ onFilterChange }: FilterProperties) => {
  const [filters, setFilters] = useState({
    propertyType: PropertyType.house,
    listingType: ListingType.sale,
  });

  const handlePropertyFilterChange = (option: PropertyType) => {
    setFilters((prev) => ({
      ...prev,
      propertyType: option,
    }));
    onFilterChange(option, filters.listingType);
  };

  const handleListingFilterChange = (option: ListingType) => {
    setFilters((prev) => ({
      ...prev,
      listingType: option,
    }));
    onFilterChange(filters.propertyType, option);
  };

  return (
    <Stack
      position={'sticky'}
      top={{ base: 24, lg: 32 }}
      left={'50%'}
      zIndex={1}
      direction={'column'}
      alignItems={'center'}
      gap={2}
      w={'full'}
    >
      <Stack
        gap={{ base: 1, md: 2 }}
        direction={{ base: 'column', sm: 'row' }}
        bg={{ base: 'light.100', sm: 'transparent' }}
        borderRadius={'xl'}
        boxShadow={{ base: 'md', sm: 'none' }}
        w={'max-content'}
      >
        <Switch
          options={[
            { name: PropertyType.house, icon: <IconHome /> },
            {
              name: PropertyType.apartment,
              icon: <IconBuildingCommunity />,
            },
          ]}
          onChange={(option) =>
            handlePropertyFilterChange(option as PropertyType)
          }
        />
        <Switch
          options={[
            { name: ListingType.sale, icon: <IconShoppingCart /> },
            { name: ListingType.rent, icon: <IconDoorEnter /> },
          ]}
          onChange={(option) =>
            handleListingFilterChange(option as ListingType)
          }
        />
      </Stack>
    </Stack>
  );
};

export default MapFilter;
