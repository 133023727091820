import { Stack, Flex, Spacer, Box, Text } from '@chakra-ui/react';

const ChartLegend = () => {
  return (
    <Box
      position={'absolute'}
      bottom={{ base: 4, md: 8 }}
      right={{ base: 0, lg: 4 }}
      zIndex={1}
    >
      <Stack
        direction={{ base: 'column', md: 'column-reverse' }}
        spacing={{ base: 1, md: 2 }}
      >
        <Text
          fontWeight="bold"
          fontSize={{ base: 'small', md: 'medium' }}
          textAlign={'right'}
        >
          Price per m<sup>2</sup>
        </Text>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          alignSelf={'end'}
          spacing={{ base: 1, md: 2 }}
        >
          <Flex
            direction={{ base: 'row-reverse', md: 'column' }}
            fontSize={{ base: 'small', md: 'medium' }}
          >
            <Text>High</Text>
            <Spacer />
            <Text>Low</Text>
          </Flex>
          <Box
            w={{ base: 24, md: 6 }}
            h={{ base: 4, md: 24 }}
            bg={{
              base: 'linear-gradient(to left, #ff0000, #00ff00)',
              md: 'linear-gradient(to bottom, #ff0000, #00ff00)',
            }}
            opacity={0.9}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default ChartLegend;
